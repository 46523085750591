import React from "react";
import { ContactForm } from "../components/ContactForm";
import { Layout } from "../components/Layout";

const Contact = () => {
    return (
        <Layout>
            <ContactForm />
        </Layout>
    )
}

export default Contact;